import types from './mutationTypes';

export default {
  [types.SET_CONFIG](state, config) {
    state.config = config;
  },
  [types.CLEAR_SELECTION](state, minBetAmount) {
    const selectedBet = {
      stake: minBetAmount,
      market: '',
      outcome: '2.00',
      roundNumber: null,
      marketId: 1,
    };
    state.selectedBet = selectedBet;
  },
  [types.CLEAR_STAKE](state, minBetAmount) {
    state.selectedBet.stake = minBetAmount;
  },
  [types.SET_STAKE](state, stake) {
    state.selectedBet.stake = stake;
  },
  [types.SET_OUTCOME](state, outcome) {
    state.selectedBet.outcome = outcome;
  },
  [types.SET_EVENT_TIME](state, payload) {
    state.eventTime = payload;
  },
  [types.SET_EVENT_ID](state, payload) {
    state.eventId = payload;
    state.selectedBet.roundNumber = Number(payload);
  },
  [types.SET_EVENT_NAME](state, payload) {
    state.eventName = payload;
  },
  [types.SET_TAXES](state, taxes) {
    state.taxes = taxes;
  },
  [types.SET_MARKET](state, payload) {
    state.selectedBet.market = payload;
  },
  [types.SET_DEVICE](state, device) {
    state.device = device;
  },
  [types.SET_BETSHOP](state, betshop) {
    state.betshop = betshop;
  },
  [types.SET_COMPANY](state, company) {
    state.company = company;
  },
  [types.SET_ACCOUNT](state, account) {
    state.account = account;
  },
  [types.SET_TERMINAL_USER_DATA](state, user) {
    state.terminalUser = user;
  },
  [types.SET_TICKET_CHECK_DATA](state, data) {
    state.ticketCheckData = data;
  },
  [types.CLEAR_TIKCET_CHECK_DATA](state) {
    state.ticketCheckData = null;
  },
  [types.SET_TICKET_HISTORY_FETCH_DATA](state, payload) {
    state.ticketHistoryFetchData = payload;
  },
  [types.SET_TICKET_HISTORY](state, payload) {
    state.ticketHistory = payload;
  },
  [types.SET_ACTIVE_COMPONENT](state, payload) {
    state.activeComponent = payload;
  },
  [types.SET_STAKE_VALUE](state, payload) {
    state.stakeValue = payload;
  },
  [types.SET_PICK_VALUE](state, payload) {
    state.pickValue = payload;
  },
  [types.SET_TICKET_ACTION](state, payload) {
    state.ticketAction = payload;
  },
  [types.SET_RESULTS](state, results) {
    state.results = results;
  },
  [types.SET_TERMINAL_SETTINGS_DATA](state, settings) {
    state.terminalSettings = settings;
  },
  [types.SET_STAKE_VALUES](state, stakeValues) {
    state.stakeValues = stakeValues;
  },
  [types.SET_PICK_VALUES](state, pickValues) {
    state.pickValues = pickValues;
  },
  [types.SET_GAME_NOT_ACTIVE](state, payload) {
    state.gameActive = false;
    state.errorMessage = payload;
  },
  [types.SET_DEVICE_ID](state, deviceId) {
    state.deviceId = deviceId;
  },
  [types.SET_IS_WEB_TERMINAL](state, isWebTerminal) {
    state.isWebTerminal = isWebTerminal;
  },
  [types.SET_TOKEN](state, token) {
    state.token = token;
  },
  [types.SET_APP_SHOULD_CONTINUE_INIT](state, shouldContinueInit) {
    state.appShouldCountinueInit = shouldContinueInit;
  },
  [types.SET_ARROWS_VISIBILITY](state, value) {
    state.arrowVisible = value;
  },
  [types.SET_HISTORY_FETCH_IN_PROGRESS](state, value) {
    state.ticketHistoryFetchInProgress = value;
  },
  [types.SET_OUTCOME_SELECTED](state, bool) {
    state.isOutcomeSelected = bool;
  },
  [types.SET_BETTING_DISABLED](state, bettingDisabled) {
    state.bettingDisabled = bettingDisabled;
  },
  [types.SET_RECONNECT_STATE](state, value) {
    state.connectionReconnectActive = value;
  },
  [types.INCREASE_RECONNECT_ATEMPTS](state) {
    state.reconnectAtemptCounter++;
  },
  [types.RESET_RECONNECT_ATEMPTS_COUNTER](state) {
    state.reconnectAtemptCounter = 0;
  },
  [types.SET_UPCOMING_EVENTS](state, upcomingEvents) {
    state.upcomingEvents = upcomingEvents;
  },
};
