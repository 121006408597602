export default {
  config: (state) => state.config,
  messaging: (state) => state.messaging,
  selectedBet: (state) => state.selectedBet,
  minBetAmount: (state) => state.config.rules?.minBetAmount?.value,
  maxBetAmount: (state) => state.config.rules?.maxBetAmount?.value,
  maxBetNumber: (state) => state.config.rules?.maxBetNumber?.value,
  maxPossibleMultiplier: (state) => state.config.rules?.maxPossibleMultiplier.value,
  stake: (state) => state.selectedBet.stake,
  outcome: (state) => state.selectedBet.outcome,
  eventInProgress: (state) => state.eventName && state.eventName !== 'idle',
  getTranslation: (state) => (key) => state.translations.translations[key] || key,
  company: (state) => state.company,
  ticketCheckData: (state) => state.ticketCheckData,
  eventId: (state) => state.eventId,
  roundId: (state) => +state.eventId,
  ticketHistoryFetchData: (state) => state.ticketHistoryFetchData,
  terminalUser: (state) => state.terminalUser,
  gameName: (state) => state.gameName,
  ticketHistory: (state) => state.ticketHistory,
  deviceId: (state) => state.device?.deviceUuid || state.deviceId,
  statusTranslations: (state) => state.statusTranslations,
  printLayoutType: (state) => state.printLayoutType,
  actionTypes: (state) => state.actionTypes,
  activeComponent: (state) => state.activeComponent,
  getInputValue: (state) => (id) => id === 2 ? state.stakeValue : state.pickValue,
  ticketAction: (state) => state.ticketAction,
  results: (state) => state.results,
  cursorEnabled: (state) => state.terminalSettings?.showCursor?.value ?? state.terminalSettings?.showCursor,
  hasUserInteracted: (state) => (id) => id === 2 ? state.userInteractedStake : state.userInteractedPick,
  pickValues: (state) => state.pickValues,
  stakeValues: (state) => state.stakeValues,
  gameActive: (state) => state.gameActive,
  isWebTerminal: (state) => state.isWebTerminal,
  token: (state) => state.token,
  payinAllowed: (state) => state.payinAllowed,
  arrowVisible: (state) => state.arrowVisible,
  account: (state) => state.account,
  isBettingDisabled: (state) => state.bettingDisabled,
  googleTagConfig: (state, getters) => getters.config?.ui?.config?.gtm ?? {},
  ticketHistoryFetchInProgress: (state) => state.ticketHistoryFetchInProgress,
  connectionReconnectActive: (state) => state.connectionReconnectActive,
  reconnectLimitReached: (state) => state.reconnectAtemptCounter >= state.maxReconnectAtemptNumber,
  upcomingEvents: (state) => state.upcomingEvents,
  totalFutureBetsCount: (state) => state.gamesBetslip.ticket.reduce((total, bet) => total + bet.numEvents, 0),
};
